body {
  background-color: #f0f0f0;/* Change this hex color to your desired background color */
}

.navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #0b9292;
}

.navbar li {
  float: left;
}

.navbar li a {
  display: block;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar li a:hover {
  background-color: #070a39;
}
