.contact-form {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(45, 161, 16, 0.1);
}

.contact-form h2 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group textarea {
  height: 100px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #065858;
  color: white;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0b7e27;
}

/* Styles for Success Screen */
.success-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}

.success-screen h2 {
  color:#1f8a0c;
  margin-bottom: 20px;
}

.success-screen button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color:#0e6324;
  color: white;
  cursor: pointer;
  font-size: 1em;
}

/* Optional: Overlay to slightly darken the background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

